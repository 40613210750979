import React from 'react'
import { map } from 'ramda'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import Layout from 'components/Layout'
import SEO from '../components/SEO'

const Item = styled(Link)`
  display: block;
  box-shadow: 1px 2px 8px #d4d4d5;
  margin: 0 0 1rem 0;
  padding: 1rem;
`
const Title = styled.h2`
  font-size: 1.5rem;
  color: #ba2c73;
  margin-bottom: 0.5rem;
`
const Date = styled.div`
  opacity: 0.5;
  margin-bottom: 0.5rem;
`
const Summary = styled.div``

const renderPosts = map(edge => {
  const { node: post } = edge

  const html = post.childContentfulPostBodyTextNode.childMarkdownRemark.excerpt
  return (
    <Item key={post.id} to={`/blog/${post.slug}`}>
      <Title>{post.title}</Title>
      <Date>{post.publishedAt}</Date>
      <Summary dangerouslySetInnerHTML={{ __html: html }} />
    </Item>
  )
})

const Blog = ({
  data: {
    allContentfulPost: { edges: posts },
  },
}) => (
  <Layout>
    <SEO data={{ title: 'Blog' }} />
    <h1>Blog</h1>
    {renderPosts(posts)}
  </Layout>
)

export const Posts = graphql`
  query Posts {
    allContentfulPost(
      filter: { node_locale: { eq: "de" } }
      sort: { fields: [publishedAt], order: DESC }
    ) {
      edges {
        node {
          id
          title
          slug
          createdAt(formatString: "LLL")
          publishedAt(formatString: "LLL")
          childContentfulPostBodyTextNode {
            id
            childMarkdownRemark {
              id
              excerpt
            }
          }
        }
      }
    }
  }
`

export default Blog
